<template>
  <el-dialog :visible="visible" width="800px" @close="handleClose">
    <template #title>
      <div class="dialog-header" style="justify-content: flex-start; gap: 10px">
        <span>{{
          promotion.id ? "Редактирование акции" : "Создание новой акции"
        }}</span>
        <el-select
          v-if="promotion.id"
          v-model="formData.status"
          placeholder="Статус"
          style="width: 160px"
          size="small"
        >
          <el-option label="Черновик" value="DRAFT" />
          <el-option label="Опубликована" value="PUBLISHED" />
          <el-option label="Завершена" value="COMPLETED" />
          <el-option label="Отменена" value="CANCELLED" />
        </el-select>
      </div>
    </template>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      class="promotion-form"
      :validate-on-rule-change="false"
    >
      <div class="form-grid">
        <el-form-item label="Название" prop="name">
          <el-input
            v-model="formData.name"
            placeholder="Введите название акции"
          />
        </el-form-item>

        <el-form-item label="Тип" prop="type">
          <el-select
            v-model="formData.type"
            placeholder="Выберите тип акции"
            style="width: 100%"
          >
            <el-option label="Скидка" value="DISCOUNT" />
            <el-option label="Подарок товаром" value="PRODUCT_GIFT" />
            <el-option label="Подарок мерчем" value="MERCH_GIFT" />
            <el-option label="Подарок озон карта" value="OZON_CARD" />
            <el-option
              label="Подарок торговое оборудование"
              value="EQUIPMENT"
            />
            <el-option label="Бонусные баллы" value="BONUS_POINTS" />
          </el-select>
        </el-form-item>

        <el-form-item label="Тип отправки приза" prop="prizeSendType">
          <el-select
            v-model="formData.prizeSendType"
            placeholder="Выберите тип отправки приза"
            style="width: 100%"
          >
            <el-option label="По мере достижения" value="progress" />
            <el-option label="По окончании" value="end" />
            <el-option label="По мере достижения и по окончании" value="both" />
          </el-select>
        </el-form-item>

        <el-form-item
          label="Баннер акции"
          prop="banner"
          class="full-width el-form-item--banner"
        >
          <el-upload
            class="banner-uploader"
            :auto-upload="false"
            :show-file-list="false"
            :on-change="handleBannerChange"
            :before-upload="beforeBannerUpload"
            drag
            action=""
          >
            <img v-if="previewUrl" :src="previewUrl" class="banner-preview" />
            <i v-else class="el-icon-upload" />
            <div class="el-upload__text" v-if="!previewUrl">
              Перетащите изображение сюда или <em>нажмите для загрузки</em>
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item label="Описание" prop="description" class="full-width">
          <div
            class="description-field"
            @click="
              showDescriptionDialog = true;
              description = formData.description;
            "
          >
            <el-input
              v-model="formData.description"
              type="textarea"
              :rows="3"
              placeholder="Введите описание акции"
              readonly
            />
          </div>
        </el-form-item>

        <div class="date-range">
          <el-form-item label="Дата начала" prop="startDate" class="date-item">
            <el-date-picker
              v-model="formData.startDate"
              type="date"
              placeholder="Выберите дату"
              format="dd-MM-yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            />
          </el-form-item>

          <el-form-item label="Дата окончания" prop="endDate" class="date-item">
            <el-date-picker
              v-model="formData.endDate"
              type="date"
              placeholder="Выберите дату"
              format="dd-MM-yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            />
          </el-form-item>
        </div>

        <el-form-item label="Товары" prop="products">
          <el-select
            v-model="formData.products"
            multiple
            filterable
            remote
            placeholder="Поиск товаров"
            :remote-method="searchProductsHandler"
            :loading="productsLoading"
            value-key="id"
            style="width: 100%"
            @change="handleProductsChange"
            @focus="handleFocus"
          >
            <el-option
              v-for="(item, index) in productOptions"
              :key="`${item.id}-${index}`"
              :label="item.name"
              :value="item"
            >
              <span>{{ item.name }}</span>
              <span style="margin-left: 5px; color: #8492a6; font-size: 13px">{{
                item.article
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="['PRODUCT_GIFT', 'MERCH_GIFT', 'OZON_CARD', 'EQUIPMENT'].includes(formData.type)"
          label="Выбор подарка"
          prop="prizesProductIds"
        >
          <el-select
            v-model="formData.prizesProductIds"
            multiple
            filterable
            remote
            placeholder="Поиск товаров для подарка"
            :remote-method="searchProductsHandler"
            :loading="productsLoading"
            value-key="id"
            style="width: 100%"
            @change="handlePrizesProductsChange"
            @focus="handleFocus"
          >
            <el-option
              v-for="(item, index) in productOptions"
              :key="`${item.id}-${index}`"
              :label="item.name"
              :value="item"
            >
              <span>{{ item.name }}</span>
              <span style="margin-left: 5px; color: #8492a6; font-size: 13px">{{
                item.article
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Целевое количество" prop="targetQuantity">
          <el-input-number
            v-model="formData.targetQuantity"
            :min="0"
            :precision="2"
            style="width: 100%"
            placeholder="Введите целевое количество"
          />
        </el-form-item>

        <template v-if="formData.type === 'DISCOUNT'">
          <el-form-item label="Процент скидки" prop="reward.value">
            <el-input-number
              v-model="formData.reward.value"
              :min="0"
              :max="100"
              :precision="2"
              style="width: 100%"
              placeholder="Введите процент скидки"
            />
          </el-form-item>
        </template>

        <template v-if="formData.type === 'BONUS_POINTS'">
          <el-form-item label="Количество баллов" prop="reward.value">
            <el-input-number
              v-model="formData.reward.value"
              :min="0"
              :precision="0"
              style="width: 100%"
              placeholder="Введите количество баллов"
            />
          </el-form-item>
        </template>

        <el-form-item label="Уведомления">
          <div class="notification-options">
            <el-checkbox v-model="formData.notifyByPush">
              Пуш уведомление
            </el-checkbox>
            <el-checkbox v-model="formData.notifyByEmail">
              Уведомление на email
            </el-checkbox>
          </div>
        </el-form-item>

        <el-form-item
          v-if="formData.notifyByPush || formData.notifyByEmail"
          label="Частота уведомлений"
          prop="notificationFrequency"
        >
          <el-input-number
            v-model="formData.notificationFrequency"
            :min="1"
            :precision="0"
            style="width: 100%"
          />
        </el-form-item>
      </div>

      <el-dialog
        title="Редактирование описания"
        :visible="showDescriptionDialog"
        width="60%"
        append-to-body
        @close="showDescriptionDialog = false"
      >
        <el-input
          v-model="description"
          type="textarea"
          :rows="20"
          placeholder="Введите описание акции"
          autofocus
        />
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="applyDescription"
            >Применить</el-button
          >
        </span>
      </el-dialog>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">Отмена</el-button>
      <el-button type="primary" :loading="loading" @click="submitForm">
        {{ promotion.id ? "Обновить" : "Создать" }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PromotionForm",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    promotion: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDescriptionDialog: false,
      description: "",
      previewUrl: "",
      formData: {
        name: "",
        description: "",
        type: "DISCOUNT",
        prizeSendType: "progress",
        startDate: "",
        endDate: "",
        targetQuantity: 0,
        reward: { value: 0 },
        products: [],
        prizesProductIds: [],
        notifyByPush: false,
        notifyByEmail: false,
        notificationFrequency: 1,
        status: "DRAFT",
      },
      banner: null,
      bannerUrl: "",
      rules: {
        name: [
          {
            required: true,
            message: "Пожалуйста, введите название",
            trigger: "input",
          },
          {
            min: 3,
            message: "Название должно содержать минимум 3 символа",
            trigger: "input",
          },
        ],
        description: [
          {
            required: true,
            message: "Пожалуйста, введите описание",
            trigger: ["input", "change", "blur"],
          },
        ],
        status: [
          {
            required: true,
            message: "Пожалуйста, выберите статус",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "Пожалуйста, выберите тип акции",
            trigger: "change",
          },
        ],
        prizeSendType: [
          {
            required: true,
            message: "Пожалуйста, выберите тип отправки приза",
            trigger: "change",
          },
        ],
        startDate: [
          {
            required: true,
            message: "Пожалуйста, выберите дату начала",
            trigger: "change",
          },
        ],
        endDate: [
          {
            required: true,
            message: "Пожалуйста, выберите дату окончания",
            trigger: "change",
          },
        ],
        products: [
          {
            required: true,
            message: "Пожалуйста, выберите товары",
            trigger: "change",
            validator: (rule, value, callback) => {
              if (!this.formData.products.length) {
                callback(new Error(rule.message));
              } else {
                callback();
              }
            },
          },
        ],
        targetQuantity: [
          {
            required: true,
            message: "Пожалуйста, укажите целевое количество",
            trigger: "change",
          },
        ],
        "reward.value": [
          {
            required: true,
            message: "Пожалуйста, укажите значение",
            trigger: "change",
          },
        ],
        prizesProductIds: [
          {
            required: true,
            message: "Пожалуйста, выберите товары для подарка",
            trigger: "change",
            validator: (rule, value, callback) => {
              if (['PRODUCT_GIFT', 'MERCH_GIFT', 'OZON_CARD', 'EQUIPMENT'].includes(this.formData.type) && !this.formData.prizesProductIds.length) {
                callback(new Error(rule.message));
              } else {
                callback();
              }
            },
          },
        ],
      },
      productsLoading: false,
      productOptions: [],
    };
  },
  computed: {
    ...mapGetters("promotions", ["getProductSearchResults"]),
  },
  watch: {
    promotion: {
      handler(newVal) {
        if (newVal.id) {
          this.formData = { ...newVal };
          if (
            typeof this.formData.reward !== "object" ||
            this.formData.reward === null
          ) {
            const rewardValue =
              typeof this.formData.reward === "number"
                ? this.formData.reward
                : 0;
            this.formData.reward = { value: rewardValue };
          }
          if (Array.isArray(this.formData.PromotionProducts)) {
            this.formData.products = this.formData.PromotionProducts.map(
              (p) => p.Product
            );
            this.productOptions = [
              ...this.formData.PromotionProducts.map((p) => p.Product),
            ];
            delete this.formData.PromotionProducts;
          } else if (Array.isArray(this.formData.products)) {
            this.productOptions = [...this.formData.products];
          }
          if (this.formData.bannerUrl) {
            this.previewUrl = this.formData.bannerUrl;
          }
        } else {
          this.resetForm();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("promotions", ["searchProducts"]),
    applyDescription() {
      this.showDescriptionDialog = false;
      this.formData.description = this.description;
      this.$nextTick(() => {
        this.$refs.form.validateField("description");
      });
    },
    handleProductsChange(value) {
      this.formData.products = value;
      this.productOptions = [...new Set([...value, ...this.productOptions])];
      this.$nextTick(() => {
        this.$refs.form.clearValidate("products");
        this.$refs.form.validateField("products");
      });
    },
    handlePrizesProductsChange(value) {
      this.formData.prizesProductIds = value;
      this.productOptions = [...new Set([...value, ...this.productOptions])];
      this.$nextTick(() => {
        this.$refs.form.clearValidate("prizesProductIds");
        this.$refs.form.validateField("prizesProductIds");
      });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.$emit("close");
    },
    resetForm() {
      this.formData = {
        name: "",
        description: "",
        type: "DISCOUNT",
        prizeSendType: "progress",
        startDate: "",
        endDate: "",
        targetQuantity: 0,
        reward: { value: 0 },
        products: [],
        prizesProductIds: [],
        notifyByPush: false,
        notifyByEmail: false,
        notificationFrequency: 1,
        status: "DRAFT",
      };
      this.previewUrl = "";
      this.productOptions = [];
    },
    async searchProductsHandler(query) {
      if (query) {
        this.productsLoading = true;
        try {
          await this.searchProducts(query);
          this.productOptions = this.getProductSearchResults;
        } finally {
          this.productsLoading = false;
        }
      } else {
        this.productOptions = [];
      }
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const formattedData = { ...this.formData };
          formattedData.productIds = (formattedData.products || []).map(
            (product) => product.id
          );
          formattedData.prizesProductIds = (formattedData.prizesProductIds || []).map(
            (product) => product.id
          );
          delete formattedData.products;
          delete formattedData.prizesProductIds;
          this.$emit(this.promotion.id ? "update" : "create", {
            banner: this.banner,
            data: formattedData,
          });
        } else {
          this.$message({
            message: "Пожалуйста, заполните все обязательные поля корректно",
            type: "warning",
          });
          return false;
        }
      });
    },
    handleFocus() {
      this.productOptions = [];
    },
    handleBannerChange(file) {
      this.banner = file.raw;
      this.bannerUrl = "";
      this.previewUrl = URL.createObjectURL(file.raw);
    },
    beforeBannerUpload(file) {
      const isImage = file.type.startsWith("image/");
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isImage) {
        this.$message.error("Загружаемый файл должен быть изображением!");
        return false;
      }
      if (!isLt5M) {
        this.$message.error("Размер изображения не должен превышать 5MB!");
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="sass" scoped>
.promotion-form
  width: 100%

.dialog-header
  display: flex
  justify-content: space-between
  align-items: center

  .status-select
    margin-bottom: 0
    margin-left: 20px

  .status-edit
    margin-right: 0

.form-grid
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: 20px

  :deep(.el-form-item)
    width: 100%
    margin-bottom: 18px

  :deep(.el-form-item.full-width)
    grid-column: 1 / -1

  :deep(.el-form-item.notification-options)
    grid-column: 1 / -1

  :deep(.el-form-item--banner)
    .el-form-item__content
      line-height: 1
      border: none

    .el-upload
      width: 100%
      height: 100%

    .el-upload-dragger
      width: 100%
      height: auto

.date-range
  display: flex
  gap: 16px

  .date-item
    flex: 1

.notification-options
  display: flex
  flex-direction: column

@media (max-width: 768px)
  .form-grid
    grid-template-columns: 1fr

  .promotion-form
    padding: 0 10px

.description-field
  cursor: pointer
  transition: all 0.3s ease

  &:hover
    opacity: 0.8

  .el-input
    width: 100%

.banner-uploader
  width: 100%


  .banner-preview
    width: 100%
    object-fit: contain
    display: block
    border-radius: 4px

  .el-upload__text
    color: #606266
    font-size: 14px
    text-align: center
    margin-top: 10px
    line-height: 1.4
</style>
