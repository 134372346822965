<template>
  <div class="personal">
    <div
      class="promotions-page"
    >
      <page-header class="header">
        Акции
        <template #subtitle>
          Управление акциями по товарам для клиентов
        </template>
        <template #rightArea>
          <el-button
            icon="el-icon-plus"
            type="primary"
            size="mini"
            @click="openCreateForm"
          >
            Добавить новую акцию
          </el-button>
        </template>
      </page-header>

      <!-- Фильтры -->
      <div class="filters-section">
        <el-row :gutter="16">
          <el-col :span="8">
            <el-input
              v-model="filters.search"
              placeholder="Поиск по акциям"
              prefix-icon="el-icon-search"
              clearable
              @input="applyFilters"
            />
          </el-col>
          <el-col :span="8">
            <el-select
              v-model="filters.status"
              placeholder="Статус акции"
              clearable
              multiple
              style="width: 100%"
              @change="applyFilters"
            >
              <el-option
                v-for="(label, value) in promotionStatuses"
                :key="value"
                :label="label"
                :value="value"
              />
            </el-select>
          </el-col>
          <el-col :span="8">
            <el-select
              v-model="filters.type"
              placeholder="Тип акции"
              clearable
              multiple
              collapse-tags
              style="width: 100%"
              @change="applyFilters"
            >
              <el-option
                v-for="(label, value) in promotionTypes"
                :key="value"
                :label="label"
                :value="value"
              />
            </el-select>
          </el-col>
        </el-row>
        <el-row
          :gutter="16"
          style="margin-top: 16px"
        >
          <el-col :span="12">
            <div class="date-range-filter">
              <div>Период начала:</div>
              <el-date-picker
                v-model="startDateRange"
                type="daterange"
                range-separator="—"
                start-placeholder="От"
                end-placeholder="До"
                format="dd.MM.yyyy"
                value-format="yyyy-MM-dd"
                @change="handleStartDateChange"
              />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="date-range-filter">
              <div>Период завершения:</div>
              <el-date-picker
                v-model="endDateRange"
                type="daterange"
                range-separator="—"
                start-placeholder="От"
                end-placeholder="До"
                format="dd.MM.yyyy"
                value-format="yyyy-MM-dd"
                @change="handleEndDateChange"
              />
            </div>
          </el-col>
        </el-row>
        <el-row
          :gutter="16"
          style="margin-top: 16px"
        >
          <el-col :span="6">
            <el-select
              v-model="filters.sortBy"
              placeholder="Сортировка"
              style="width: 100%"
              @change="applyFilters"
            >
              <el-option
                label="По дате создания"
                value="createdAt"
              />
              <el-option
                label="По дате начала"
                value="startDate"
              />
              <el-option
                label="По дате завершения"
                value="endDate"
              />
              <el-option
                label="По количеству нажатий"
                value="clickCount"
              />
              <el-option
                label="По количеству просмотров"
                value="viewCount"
              />
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select
              v-model="filters.sortOrder"
              placeholder="Порядок"
              style="width: 100%"
              @change="applyFilters"
            >
              <el-option
                label="По убыванию"
                value="DESC"
              />
              <el-option
                label="По возрастанию"
                value="ASC"
              />
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-input
              v-model.number="filters.productId"
              placeholder="ID товара"
              clearable
              type="number"
              @input="applyFilters"
            />
          </el-col>
        </el-row>
      </div>

      <div
        v-loading="promotionsLoading"
        class="promotions-table"
      >
        <div
          v-for="promotion in promotions"
          :key="promotion.id"
          class="promotion"
        >
          <div class="promotion-content">
            <div class="promotion-header">
              <div style="display: flex;gap: 10px;align-items: center">
                <h3>{{ promotion.name }}</h3>
                <el-tag effect="plain">
                  {{ getPromotionStatusName(promotion.status) }}
                </el-tag>
              </div>
              <div class="promotion-actions">
                <el-button
                  type="text"
                  icon="el-icon-edit"
                  @click="openEditForm(promotion)"
                />
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  @click="deletePromotion(promotion.id)"
                />
              </div>
            </div>
            <p class="promotion-description">
              {{ promotion.description }}
            </p>
            <div class="promotion-details">
              <span class="promotion-type">Тип: {{ getPromotionTypeName(promotion.type) }}</span>
              <span class="promotion-dates">
                {{ formatDate(promotion.startDate) }} - {{ formatDate(promotion.endDate) }}
              </span>
              <div class="promotion-stats">
                <el-tooltip
                  content="Количество просмотров"
                  placement="top"
                >
                  <span class="promotion-stat-item">
                    <i class="el-icon-view" /> {{ promotion.dismissCount || 0 }}
                  </span>
                </el-tooltip>
                <el-tooltip
                  content="Количество кликов"
                  placement="top"
                >
                  <span class="promotion-stat-item">
                    <i class="el-icon-mouse" /> {{ promotion.clickCount || 0 }}
                  </span>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <el-empty
          v-if="!promotionsLoading && !promotions.length"
          description="Список акций пуст"
        />
      </div>

      <!-- Пагинация -->
      <div
        v-if="totalPromotions > 0"
        class="pagination-container"
      >
        <el-pagination
          :current-page="filters.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="filters.limit"
          layout="total, prev, pager, next"
          :total="totalPromotions"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>

    <!-- Компонент формы акции -->
    <promotion-form
      :visible="isFormOpen"
      :promotion="selectedPromotion"
      :loading="formLoading"
      @close="closeForm"
      @create="handleCreatePromotion"
      @update="handleUpdatePromotion"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

import PageHeader from "../../components/page-header/page-header.vue";
import PromotionForm from "../../components/PromotionForm.vue";

// Константы для фильтров
const PROMOTION_STATUSES = {
  DRAFT: 'Черновик',
  PUBLISHED: 'Опубликована',
  COMPLETED: 'Завершена',
  CANCELLED: 'Отменена',
};

const PROMOTION_TYPES = {
  DISCOUNT: 'получи скидку',
  PRODUCT_GIFT: 'получи в подарок товар',
  MERCH_GIFT: 'получи в подарок мерч',
  BONUS_POINTS: 'получи бонус (баллы от поставщика)',
  OZON_CARD: 'получи карту ОЗОН',
  EQUIPMENT: 'получи торговое оборудование'
};

export default {
  name: "Promotions",
  components: { PageHeader, PromotionForm },
  metaInfo: {
    title: "Акции",
  },
  data() {
    return {
      isFormOpen: false,
      selectedPromotion: {},
      formLoading: false,
      promotionTypes: PROMOTION_TYPES,
      promotionStatuses: PROMOTION_STATUSES,
      totalPromotions: 0,
      startDateRange: null,
      endDateRange: null,
      filters: {
        page: 1,
        limit: 20,
        search: '',
        status: [],
        type: [],
        startDateFrom: undefined,
        startDateTo: undefined,
        endDateFrom: undefined,
        endDateTo: undefined,
        productId: undefined,
        sortBy: 'createdAt',
        sortOrder: 'DESC',
      }
    };
  },
  computed: {
    ...mapState("admin", ["promotions", "promotionsLoading"]),
  },
  mounted() {
    this.loadPromotions();
  },
  methods: {
    ...mapActions("admin", ["getPromotions", "createPromotion", "updatePromotion", "deletePromotionAction"]),

    getPromotionTypeName(type) {
      return this.promotionTypes[type] || type;
    },

    getPromotionStatusName(status) {
      return this.promotionStatuses[status] || status;
    },

    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      return date.toLocaleDateString('ru-RU');
    },

    loadPromotions() {
      this.getPromotions(this.filters)
        .then(response => {
          // Предполагаем, что ответ содержит общее количество акций для пагинации
          if (response && response.total) {
            this.totalPromotions = response.total;
          }
        })
        .catch((err) => {
          console.error("Ошибка загрузки акций", err);
          this.$message.error("Не удалось загрузить список акций");
        });
    },

    handleStartDateChange(dateRange) {
      if (dateRange) {
        [this.filters.startDateFrom, this.filters.startDateTo] = dateRange;
      } else {
        this.filters.startDateFrom = undefined;
        this.filters.startDateTo = undefined;
      }
      this.applyFilters();
    },

    handleEndDateChange(dateRange) {
      if (dateRange) {
        [this.filters.endDateFrom, this.filters.endDateTo] = dateRange;
      } else {
        this.filters.endDateFrom = undefined;
        this.filters.endDateTo = undefined;
      }
      this.applyFilters();
    },

    handleSizeChange(size) {
      this.filters.limit = size;
      this.filters.page = 1; // Сбрасываем на первую страницу при изменении размера
      this.applyFilters();
    },

    handleCurrentChange(page) {
      this.filters.page = page;
      this.applyFilters();
    },

    openCreateForm() {
      this.selectedPromotion = {};
      this.isFormOpen = true;
    },

    openEditForm(promotion) {
      this.selectedPromotion = { ...promotion };
      this.isFormOpen = true;
    },

    closeForm() {
      this.isFormOpen = false;
      this.selectedPromotion = {};
    },

    async handleCreatePromotion(promotionData) {
      this.formLoading = true;
      try {
        await this.createPromotion(promotionData);
        this.$message.success('Акция успешно создана');
        this.closeForm();
        this.loadPromotions();
      } catch (error) {
        console.error('Ошибка при создании акции', error);
        this.$message.error('Не удалось создать акцию: ' + (error.message || error));
      } finally {
        this.formLoading = false;
      }
    },

    async handleUpdatePromotion(promotionData) {
      this.formLoading = true;
      try {
        await this.updatePromotion(promotionData);
        this.$message.success('Акция успешно обновлена');
        this.closeForm();
        this.loadPromotions();
      } catch (error) {
        console.error('Ошибка при обновлении акции', error);
        this.$message.error('Не удалось обновить акцию');
      } finally {
        this.formLoading = false;
      }
    },

    async deletePromotion(id) {
      this.$confirm('Вы уверены, что хотите удалить эту акцию?', 'Подтверждение', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        type: 'warning'
      }).then(async () => {
        try {
          await this.deletePromotionAction(id);
          this.loadPromotions();
          this.$message.success('Акция успешно удалена');
        } catch (error) {
          console.error('Ошибка при удалении акции', error);
          this.$message.error('Не удалось удалить акцию');
        }
      }).catch(() => {
        // Пользователь отменил удаление
      });
    },

    // Методы для работы с фильтрами
    applyFilters() {
      // Сбрасываем на первую страницу при изменении фильтров
      if (this.filters.page !== 1) {
        this.filters.page = 1;
      }
      this.loadPromotions();
    },

    resetFilters() {
      this.startDateRange = null;
      this.endDateRange = null;
      this.filters = {
        page: 1,
        limit: 20,
        search: '',
        status: [],
        type: [],
        startDateFrom: undefined,
        startDateTo: undefined,
        endDateFrom: undefined,
        endDateTo: undefined,
        productId: undefined,
        sortBy: 'createdAt',
        sortOrder: 'DESC',
      };
      this.loadPromotions();
    }
  },
};
</script>

<style lang="sass" scoped>
.promotions-page
  max-width: 800px
  margin-left: auto
  margin-right: auto
  padding-bottom: 40px

.header
  margin-bottom: 20px

.filters-section
  margin-bottom: 24px
  padding: 16px
  background-color: #f9f9f9
  border-radius: 8px
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05)

.filter-buttons
  display: flex
  justify-content: flex-end
  gap: 10px

.date-range-filter
  font-size: 14px
  display: flex
  flex-direction: column
  gap: 8px

.promotions-table
  display: flex
  flex-direction: column
  gap: 16px

.pagination-container
  margin-top: 20px
  display: flex
  justify-content: center

.promotion
  border: 1px solid #ebeef5
  box-shadow: 0 9px 22px 0 rgba(0, 0, 50, .05)
  border-radius: 8px
  overflow: hidden

.promotion-content
  padding: 16px

.promotion-header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 8px

  h3
    margin: 0
    font-size: 18px

.promotion-actions
  display: flex
  gap: 8px
  margin-left: 10px

.promotion-description
  margin-bottom: 12px
  color: #606266

.promotion-details
  display: flex
  justify-content: space-between
  font-size: 14px
  gap: 10px
  color: #909399

.promotion-stats
  display: flex
  gap: 15px

.promotion-stat-item
  display: flex
  align-items: center
  gap: 4px

  i
    font-size: 16px
</style>
