import Vue from 'vue';
import Vuex from 'vuex';
import createMutationsSharer from 'vuex-shared-mutations';

import actions from './actions';
import getters from './getters';
import accounts from './module/accounts';
import admin from './module/admin';
import auth from './module/auth';
import basket from './module/basket';
import categories from './module/categories';
import contactsConsignees from './module/contactsConsignees';
import documents from './module/documents.js';
import events from './module/events';
import excel from './module/excel';
import garage from './module/garage';
import laximo from './module/laximo';
import order from './module/order';
import orderItems from './module/orderItems';
import personalCabinet from './module/personalCabinet';
import products from './module/products';
import receivables from './module/receivables';
import recommendations from './module/recommendations';
import reconciliationReports from './module/reconciliationReports';
import returns from './module/returns';
import tecdoc from './module/tecdoc';
import ui from './module/ui';
import wishlist from './module/wishlist';
import promotions from './module/promotions.js';
import mutations from './mutations';
import socketIOPlug from './socketIOPlug';
import state from './state';

Vue.use(Vuex);
export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    ui,
    auth,
    products,
    categories,
    basket,
    accounts,
    order,
    tecdoc,
    admin,
    laximo,
    wishlist,
    receivables,
    returns,
    orderItems,
    excel,
    reconciliationReports,
    personalCabinet,
    garage,
    recommendations,
    events,
    documents,
    contactsConsignees,
    promotions
  },
  plugins: [
    socketIOPlug,
    createMutationsSharer({
      predicate: [
        'basket/ADD_PRODUCTS_IN_BASKET',
        'basket/SET_ACTIVE_BASKET',
        'basket/SET_MULTI_BASKET',
        'basket/SET_ITEM_QUANTITY',
        'basket/SET_PRODUCT_BASKET_ITEM_ID',
        'basket/SET_MULTI_BASKET_ITEM_QUANTITY',
        'products/SET_PRODUCT_BASKET_QUANTITY'
      ]
    })
  ]
});
