<template>
  <div>
    <div class="promo-action">
      <div class="promo-action__header">
        <div v-if="promotion.bannerUrl" class="promo-action__banner">
          <img
            :src="promotion.bannerUrl"
            alt="Promotion banner"
            @click="showFullscreenBanner"
          >
        </div>
        <div class="promo-action__main">
          <div class="promo-action__name">
            {{ promotion.name }}
          </div>
          <div class="promo-action__description">
            {{ promotion.description }}
          </div>
          <div class="promo-action__details">
            <div class="promo-action__period">
              <span>Период действия: {{ formatDate(promotion.startDate) }} - {{ formatDate(promotion.endDate) }}</span>
            </div>
            <div class="promo-action__type">
              <span>Тип: {{ getPromotionTypeName(promotion.type) }}</span>
            </div>
          </div>
        </div>
        <div class="promo-action__progress">
          <el-progress
            type="circle"
            :percentage="progressPercentage"
            :width="60"
            :stroke-width="6"
          ></el-progress>
        </div>
        <div
          class="promo-action__toggle"
          @click="toggleExpanded"
        >
          <i :class="['el-icon-arrow-down', { 'is-expanded': expanded }]" />
        </div>
      </div>

      <el-collapse-transition>
        <div
          v-show="expanded"
          class="promo-action__products"
        >
          <h4 class="promo-action__products-title">
            Товары в акции:
          </h4>
          <div
            v-for="product in products"
            :key="product.id"
            class="promo-action__product"
          >
            <div class="promo-action__product-info">
              <div class="promo-action__product-name">
                {{ product.name }}
              </div>
              <div class="promo-action__product-article">
                Артикул: {{ product.article }}
              </div>
            </div>
            <div class="promo-action__product-action">
              <template v-if="isProductInBasket(product.id)">
                <div class="promo-action__product-in-basket">
                  <i class="el-icon-check" />
                  <span>В корзине</span>
                </div>
              </template>
              <template v-else>
                <priceOutput :price="normalizePriceMarkup(getPrice(product))" />
                <add-to-cart
                  v-model="productQuantities[product.id]"
                  :min="1"
                  :step="1"
                  @addToCart="addToCart(product)"
                />
              </template>
            </div>
          </div>
          <div
            v-if="!products.length && !productsLoading"
            class="promo-action__no-products"
          >
            Нет товаров в акции
          </div>
          <div
            v-if="!products.length && productsLoading"
            class="promo-action__no-products"
          >
            Загрузка..
          </div>
        </div>
      </el-collapse-transition>
    </div>

    <!-- Fullscreen banner dialog -->
    <el-dialog
      :visible.sync="bannerDialogVisible"
      :show-close="true"
      custom-class="banner-dialog"
    >
      <img
        v-if="promotion.bannerUrl"
        :src="promotion.bannerUrl"
        alt="Promotion banner"
        class="banner-dialog__image"
      >
    </el-dialog>
  </div>
</template>

<script>
import axiosInstance from "api/axiosInstance.js";
import { getPrice, normalizePriceMarkup } from "utils/normalizePrice.js";
import { mapActions, mapState } from 'vuex';

import AddToCart from '@/components/add-to-cart/addToCart.vue';
import { PROMOTION_TYPES } from '@/store/module/promotions.js';

export default {
  name: 'PromoActionItem',
  components: {
    AddToCart
  },
  props: {
    promotion: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      expanded: false,
      productQuantities: {},
      products: [],
      productsLoading: false,
      progress: undefined,
      bannerDialogVisible: false,
      promotionTypeNames: {
        [PROMOTION_TYPES.DISCOUNT]: 'получи скидку',
        [PROMOTION_TYPES.PRODUCT_GIFT]: 'получи в подарок товар',
        [PROMOTION_TYPES.MERCH_GIFT]: 'получи в подарок мерч',
        [PROMOTION_TYPES.BONUS_POINTS]: 'получи бонус (баллы от поставщика)',
        [PROMOTION_TYPES.OZON_CARD]: 'получи карту ОЗОН',
        [PROMOTION_TYPES.EQUIPMENT]: 'получи торговое оборудование'
      }
    };
  },
  computed: {
    ...mapState('basket', ['multiBasket', 'activeBasketId']),

    selectedBasket() {
      return this.multiBasket.find(basket => basket.id === this.activeBasketId);
    },

    selectedBasketItems() {
      return this.selectedBasket?.basketItems || [];
    },


    progressPercentage() {
      if (!this.progress) {
        return 0;
      }

      const currentQuantity = this.progress.currentQuantity;
      const targetQuantity = this.progress.promotion.targetQuantity;
      return Math.min(Math.round((currentQuantity / targetQuantity) * 100), 100);
    }
  },
  mounted() {
    this.getPromotionProgress(this.promotion.id).then(response => {
      this.progress = response
    })
  },
  created() {
    // Инициализация количества товаров для каждого товара в акции
    if (this.products && this.products.length) {
      this.products.forEach(product => {
        this.$set(this.productQuantities, product.id, 1);
      });
    }
  },
  methods: {
    getPrice,
    normalizePriceMarkup,
    ...mapActions('basket', ['AddProductInBasket']),
    ...mapActions('promotions', ['getPromotionProgress']),

    toggleExpanded() {
      if (!this.expanded) {
        this.getProducts();
      }
      this.expanded = !this.expanded;
    },

    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      return date.toLocaleDateString('ru-RU');
    },

    getPromotionTypeName(type) {
      return this.promotionTypeNames[type] || type;
    },

    isProductInBasket(productId) {
      return this.selectedBasketItems.some(item => item.productId === productId);
    },

    addToCart(product) {
      this.AddProductInBasket({
        id: product.id,
        name: product.name,
        article: product.article,
        quantity: this.productQuantities[product.id] || 1
      });
      this.$message({
        message: 'Товар добавлен в корзину',
        type: 'success'
      });
    },
    getProducts() {
      this.productsLoading = true;
      axiosInstance.get('/api/productList?max=50&promotions[]=' + this.promotion.id).then(response => {
        this.products = response.products;
      }).finally(() => {
        this.productsLoading = false;
      })
    },
    showFullscreenBanner() {
      this.bannerDialogVisible = true;
    }
  }
};
</script>

<style scoped lang="sass">
  .promo-action
    margin-bottom: 20px
    background: white
    border: 1px solid #ebeef5
    box-shadow: 0 9px 22px 0 rgba(0, 0, 50, .05)
    border-radius: 8px
    width: 100%
    overflow: hidden

    &__header
      display: flex
      justify-content: space-between
      align-items: flex-start
      padding: 15px 20px

    &__banner
      width: 120px
      height: 120px
      margin-right: 20px
      flex-shrink: 0
      cursor: pointer
      overflow: hidden
      border-radius: 4px

      img
        width: 100%
        height: 100%
        object-fit: cover
        transition: transform 0.3s ease

      &:hover img
        transform: scale(1.05)

    &__main
      flex: 1

    &__name
      font-weight: bold
      font-size: 18px
      margin-bottom: 8px

    &__description
      color: #606266
      margin-bottom: 12px

    &__details
      display: flex
      flex-wrap: wrap
      gap: 15px
      font-size: 14px
      color: #606266

    &__progress
      margin: 0 20px

    &__progress-text
      font-size: 12px
      font-weight: bold

    &__toggle
      cursor: pointer

      .el-icon-arrow-down
        transition: transform 0.3s
        font-size: 20px

        &.is-expanded
          transform: rotate(180deg)

    &__products
      padding: 0 20px 20px
      border-top: 1px solid #ebeef5

    &__products-title
      margin: 15px 0
      font-size: 16px

    &__product
      display: flex
      justify-content: space-between
      align-items: center
      padding: 10px 0
      border-bottom: 1px solid #f0f0f0

      &:last-child
        border-bottom: none

    &__product-info
      flex: 1

    &__product-name
      font-weight: 500
      margin-bottom: 5px

    &__product-article
      font-size: 13px
      color: #909399

    &__product-action
      display: flex
      align-items: center
      gap: 5px
    &__product-in-basket
      display: flex
      align-items: center
      color: #67c23a
      font-weight: 500

      i
        font-size: 18px
        margin-right: 5px

    &__no-products
      padding: 20px 0
      text-align: center
      color: #909399
</style>

<style lang="sass">
.banner-dialog
  .el-dialog__body
    padding: 0
    display: flex
    justify-content: center
    align-items: center

  &__image
    max-width: 100%
    object-fit: contain
</style>
