<template>
  <div>
    <el-dropdown
      trigger="click"
      style="color:#ffffff;font-size: 12px;"
    >
      <span
        class="el-dropdown-link naw-btn"
        style="display: flex;padding: 5px;cursor: pointer"
      >
        <div>
          <div style="text-align: center;">
            <i class="el-icon-menu" />
          </div>
          <div>
            Меню
          </div>
        </div>
      </span>

      <template #dropdown>
        <el-dropdown-menu>
          <template v-if="windowSize.width <= 1200">
            <el-dropdown-item>
              <router-link
                to="/promotions"
                class="menu-link"
                :active-class="'active'"
              >
                Акции
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link
                :to="productsLink"
                class="menu-link"
                :active-class="'active'"
              >
                Товары
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link
                to="/history"
                class="menu-link"
                :active-class="'active'"
              >
                История
              </router-link>
            </el-dropdown-item>
          </template>
          <el-dropdown-item>
            <router-link
              to="/wishlist"
              class="menu-link"
              :active-class="'active'"
            >
              Лист ожидания
            </router-link>
          </el-dropdown-item>
          <el-dropdown-item>
            <router-link
              to="/upload-xls"
              class="menu-link"
              :active-class="'active'"
            >
              Загрузка Excel
            </router-link>
          </el-dropdown-item>
          <el-dropdown-item>
            <router-link
              to="/contacts"
              class="menu-link"
              :active-class="'active'"
            >
              Контакты
            </router-link>
          </el-dropdown-item>
          <el-dropdown-item>
            <span
              class="menu-link"
              @click="openExcelModal"
            >
              Скачать прайс
            </span>
          </el-dropdown-item>
          <el-dropdown-item v-if="windowSize.width <= 1200">
            <a
              href="https://nikol.biz"
              class="menu-link"
            >
              Перейти на сайт
            </a>
          </el-dropdown-item>
          <el-dropdown-item
            v-if="windowSize.width <= 1200"
            divided
          >
            <router-link
              to="/receivables"
              class="menu-link receivables-menu-item"
              :active-class="'active'"
            >
              <div class="receivables-menu-item__title">
                Взаиморасчёты
              </div>
              <div class="receivables-menu-item__summ">
                <price-output
                  :price="normalizePrice(receivable)"
                  show-rub
                />
              </div>
            </router-link>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <wishlist-modal v-if="isModalOpen" />
  </div>
</template>

<script>
import { normalizePrice } from 'utils/normalizePrice';
import { mapActions,mapState } from 'vuex';

import WishlistModal from '@/components/wishlist-modal/WishlistModal';

export default {
  name: 'AppHeaderMobileMenu',
  components: {
    WishlistModal
  },
  computed: {
    ...mapState('products', ['tourStatus', 'supplierProductsInfo', 'tableType']),
    ...mapState('wishlist', ['hasNotification', 'isModalOpen']),
    ...mapState('receivables', ['receivable']),
    ...mapState('ui', ['windowSize']),
    productsLink() {
      if (this.tableType === 'nikolProducts') return '/products';
      if (this.tableType === 'part') {
        const { brand, article } = this.supplierProductsInfo;
        return brand && article ? `/part/${brand}/${article}` : '/products';
      }
      return '/products';
    }
  },
  methods: {
    ...mapActions('excel', {
      openExcelModal: 'open'
    }),
    normalizePrice
  }
};
</script>

<style scoped lang="sass">
.menu-link
  color: rgb(96, 98, 102)
  text-decoration: none
  padding: 10px 50px 10px 20px
  white-space: pre
  &.active
    font-weight: bold
.receivables-menu-item
  display: flex
  flex-direction: column
  padding: 10px 50px 10px 20px
  line-height: 1.3
  white-space: pre

.el-dropdown-link
  height: 41px
  font-size: 12px
  text-decoration: none
  color: #ffffff !important
  display: flex
  align-items: center
  justify-content: center
  padding: 0 15px
  position: relative
  cursor: pointer
  width: max-content
  white-space: nowrap
  &:hover
    background: rgba(0,0,0,0.15)
</style>
