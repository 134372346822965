import axiosInstance from "../../api/axiosInstance";

export const PROMOTION_STATUSES = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
}
export const PROMOTION_TYPES = {
  DISCOUNT: 'DISCOUNT',
  PRODUCT_GIFT: 'PRODUCT_GIFT',
  MERCH_GIFT: 'MERCH_GIFT',
  BONUS_POINTS: 'BONUS_POINTS',
  OZON_CARD: 'OZON_CARD',
  EQUIPMENT: 'EQUIPMENT',
};

const state = () => ({
  /** @type Promotion[] **/
  promotions: [],
  /** @type Product[] **/
  productSearchResults: [],
});

const mutations = {
  SET_PROMOTIONS(state, promotions) {
    state.promotions = promotions;
  },
  SET_PRODUCT_SEARCH_RESULTS(state, products) {
    state.productSearchResults = products;
  },
};
const actions = {
  async getPromotions({ commit }) {
    await axiosInstance("/api/promotions").then((resp) => {
      commit("SET_PROMOTIONS", resp);
    });
  },
  async sendViewPromotion(_, promotionId) {
    await axiosInstance("/api/promotions/" + promotionId + "/view", {
      method: "post",
    });
  },
  async clickOnPromotion(_, promotionId) {
    await axiosInstance("/api/promotions/" + promotionId + "/click", {
      method: "post",
    });
  },
  async dismissBanner(_, promotionId) {
    await axiosInstance("/api/promotions/" + promotionId + "/dismiss-banner", {
      method: "post",
    });
  },
  async getPromotionProgress(_, promotionId) {
    return axiosInstance("/api/promotions/" + promotionId + "/progress", {
      method: "get",
    });
  },
  async searchProducts({ commit }, query) {
    const response = await axiosInstance.get("/api/productList", {
      params: {
        q: query,
        max: 50,
      }
    });
    commit("SET_PRODUCT_SEARCH_RESULTS", response.products);
    return response.products;
  },
};

const getters = {
  getProductSearchResults: (state) => state.productSearchResults,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
