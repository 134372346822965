<template>
  <div class="promo-actions">
    <page-header>
      Акции
    </page-header>

    <div class="promo-actions__basket-selector">
      <span>Корзина: </span>
      <el-select
        v-model="selectedBasketId"
        size="small"
        :loading="basketsLoading"
        style="width: 250px; margin-left: 10px;"
        @change="handleBasketChange"
      >
        <el-option
          v-for="basket in multiBasket"
          :key="basket.id"
          :label="basket.name"
          :value="basket.id"
        />
        <template v-if="!multiBasket.length">
          <el-option
            disabled
            value=""
            label="Нет доступных корзин"
          />
        </template>
      </el-select>
    </div>

    <div
      v-if="loading"
      v-loading="true"
      class="loader"
    />
    <el-empty
      v-if="!loading && !promotions.length"
      description="В данный момент нет активных акций"
    />
    <div class="promo-actions__list">
      <promo-action-item
        v-for="promotion in promotions"
        :key="promotion.id"
        :promotion="promotion"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations,mapState } from 'vuex';

import PageHeader from '@/components/page-header/page-header';
import PromoActionItem from '@/views/products/components/PromoActionItem';

export default {
  name: 'Promotions',
  components: { PromoActionItem, PageHeader },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState('promotions', ['promotions']),
    ...mapState('basket', ['multiBasket', 'activeBasketId', 'basketsLoading']),

    selectedBasketId: {
      get() {
        return this.activeBasketId;
      },
      set() {
        // Реактивное свойство для работы с v-model
        // Фактическая установка значения происходит в handleBasketChange
      }
    }
  },
  async mounted() {
    // Загрузка списка корзин
    this.SET_BASKETS_LOADING(true);
    this.GetBasketsWithItems()
      .catch(err => {
        console.error("Ошибка загрузки корзин", err);
        this.$message.error("Не удалось загрузить список корзин");
      })
      .finally(() => {
        this.SET_BASKETS_LOADING(false);
      });

    // Загрузка акций
    this.loading = true;
    this.getPromotions()
      .catch((err) => {
        console.error("Ошибка загрузки акций", err);
        this.$message.error("Не удалось загрузить список акций");
      }).finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions('promotions', ['getPromotions']),
    ...mapActions('basket', ['GetBasketsWithItems', 'GetProductsBasket']),
    ...mapMutations('basket', ['SET_ACTIVE_BASKET', 'SET_BASKETS_LOADING']),

    handleBasketChange(basketId) {
      this.SET_ACTIVE_BASKET(basketId);
      this.GetProductsBasket();
    }
  }
};
</script>

<style scoped lang="sass">
  .promo-actions
    max-width: 900px
    margin: 0 auto
    padding: 20px

    &__basket-selector
      display: flex
      align-items: center
      margin: 15px 0
      background: white
      padding: 15px
      border-radius: 8px
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05)

    &__list
      margin: 20px 0
      display: grid
      grid-row-gap: 15px
</style>
