<template>
  <aside
    id="v-step-0"
    :class="b({ collapse: collapseMenu })"
  >
    <div :class="b('wrapper', { collapse: collapseMenu })">
      <div
        class="menu-icon-container"
        @click="collapse()"
      >
        <div
          id="v-step-1"
          class="menu-icon-container__body"
        >
          <i
            class="menu-icon menu-icon_position menu-icon_color"
            :class="icon"
          />
        </div>
      </div>
      <div :class="b('body', { collapse: collapseMenu })">
        <div
          class="menu-button"
          :class="{ active: $route.path.indexOf('/tecdoc') >= 0 || tableType === 'tecdoc' }"
          @click="toTecDoc"
        >
          Общий каталог (TecDoc)
        </div>
        <div
          class="menu-button"
          :class="{ active: $route.path.indexOf('/oem') >= 0 || tableType === 'laximo' }"
          @click="toLaximo"
        >
          Оригинальные каталоги
        </div>
        <app-sidebar :key="'nikolCatalog'">
          <app-tree-root
            :key="'categories'"
            :nodes="categories"
            @selected="openCategory"
          />
        </app-sidebar>
        <products-filters
          v-if="$route.name === 'view-products' && tableType === 'nikolProducts'"
          class="filters"
        />
        <parts-filters
          v-if="$route.name === 'parts'"
          v-loading="suppliersLoading"
          class="filters"
        />
      </div>
    </div>
    <div
      :class="b('bg', { collapse: collapseMenu })"
      @click="collapse()"
    />
  </aside>
</template>

<script>
import {noop} from "utils/functions";
import { mapActions,mapMutations, mapState } from 'vuex';

import AppSidebar from '@/components/layout/app-sidebar/app-sidebar';
import PartsFilters from '@/components/product-filters/partsFilters.vue';
import ProductsFilters from '@/components/product-filters/productsFilters';

import AppTreeRoot from './app-tree-root';

export default {
  name: 'AppAside',
  block: 'aside',
  components: {
    PartsFilters,
    ProductsFilters,
    AppSidebar,
    AppTreeRoot,
  },
  computed: {
    ...mapState('ui', ['collapseMenu', 'windowSize']),
    ...mapState('products', ['products', 'tableType', 'suppliersLoading']),
    ...mapState('categories', ['categories']),
    icon() {
      return this.collapseMenu ? 'el-icon-s-fold' : 'el-icon-caret-left';
    }
  },
  created() {
    if (!this.categories[0].children.length) {
      this.GetCategories().catch((err) => {
        console.error('Ошибка загрузки категорий', err);
        this.$message.error('Не удалось загрузить список категорий');
      });
    }
  },
  methods: {
    ...mapMutations('ui', ['SET_COLLAPSE_MENU']),
    ...mapActions('categories', ['GetCategories']),
    async openCategory(categoryId) {
      const path = `/products${categoryId ? `/${categoryId}` : ''}`;
      if (this.$route.path === path) {
        return;
      }
      await this.$router.push(path);
    },
    collapse() {
      this.SET_COLLAPSE_MENU(!this.collapseMenu);
    },
    toTecDoc() {
      if (this.$route.path === '/tecdoc/brand') return;
      this.SET_COLLAPSE_MENU(true);
      this.$store.commit('tecdoc/SET_MOD_ID', null);
      this.$store.commit('tecdoc/SET_MODEL_ID', null);
      this.$store.commit('tecdoc/SET_MONUFACTURER_ID', null);
      this.$store.commit('tecdoc/SET_AUTO_BY_VIN', []);
      this.$store.commit('tecdoc/SET_AUTO', []);
      this.$store.commit('tecdoc/SET_MODIFICATIONS', []);
      this.$store.commit('tecdoc/SET_TECDOC_CATEGORIES', []);
      this.$store.commit('tecdoc/SET_ARTICLES_SIMPLIFIED', []);
      this.$store.commit('tecdoc/SET_HAS_SEARCH_REQUEST', false);
      this.$store.dispatch('categories/SetCurrentCategoryData', null);

      this.$store.commit('tecdoc/SET_TAB_TYPE_BRAND', 'favorites');
      this.$store.commit('order/SET_ORDERS_EMPTY');
      this.$router.push('/tecdoc/brand');
    },
    toLaximo() {
      this.$store.commit('categories/SET_CURRENT_CATEGORY', 'l-0');
      this.$store.commit('laximo/REMOVE_ACTIVE_CATALOG');
      this.$router.push('/oem').catch(noop);
      this.SET_COLLAPSE_MENU(true);
    },
  }
};
</script>

<style lang="sass" scoped>
.aside
  width: 250px
  transition: .2s
  &_collapse
    width: 35px
  @media (max-width: 1200px)
    width: 35px !important
  &__wrapper
    position: relative
    height: 100%
    max-height: calc(100vh - 42px)
    border-right: 1px solid #ebeef5
    background: white
    width: 250px
    overflow: hidden
    transition: .2s
    z-index: 101
    &_collapse
      width: 35px
  &__bg
    display: block
    @media (max-width: 1200px)
      position: fixed
      top: 42px
      left: 0
      right: 0
      bottom: 0
      background: rgba(black, .5)
      z-index: 100
      &_collapse
        display: none
  &__body
    padding-bottom: 10px
    width: 250px
    padding-right: 0
    transform: translateX(0)
    height: calc(100% - 30px)
    overflow: auto
    transition: .2s
    &_collapse
      transform: translateX(-250px)

.menu-icon-container
  position: sticky
  top: 0
  height: 30px
  cursor: pointer
  background: white
  z-index: 5
  &__body
    position: absolute
    right: 0

.menu-icon
  padding: 10px

.menu-button
  font-size: 12px
  color: black
  padding-left: 34px
  padding-bottom: 7px
  padding-top: 7px
  cursor: pointer
  &:first-child
    margin-top: -7px
  &.active
    font-weight: bold

.filters
  padding: 0 15px

</style>
