import axiosInstance from '../../api/axiosInstance';


/**
 * @typedef {Object.<string, String[]>} Synonyms
 */

const state = () => ({
  logs: [],
  userId: null,
  fromDate: null,
  toDate: null,
  subjectFilter: '',
  allAccounts: [],
  max: 100,
  nameFilter: '',
  haveUsers: null,
  active: null,
  emptyText: 'Загрузка',
  synonyms: null,
  synonymsLoading: false,
  synonymsLoaded: false,
  healthcheckLoading: false,
  healthcheck: null,
  ordersLoading: false,
  orders: [],
  promotions: [],
  promotionsLoading: false,
});

const mutations = {
  SET_ADMIN_EMPTY_TEXT(state, emptyText) {
    state.emptyText = emptyText;
  },
  SET_HAVE_USERS_FILTER(state, haveUsers) {
    state.haveUsers = haveUsers;
  },
  SET_ACTIVE_USERS_FILTER(state, active) {
    state.active = active;
  },
  SET_NAME_FILTER(state, nameFilter) {
    state.nameFilter = nameFilter;
  },
  SET_EMPTY_ACCOUNTS(state) {
    state.allAccounts = [];
  },
  SET_ALL_ACCOUNTS(state, allAccounts) {
    // state.allAccounts = allAccounts;
    state.allAccounts = state.allAccounts.concat(allAccounts);
  },
  SET_SUBJECT_FILTER(state, subjectFilter) {
    state.subjectFilter = subjectFilter;
  },
  SET_LOGS(state, logs) {
    state.logs = logs;
  },
  SET_USER_ID(state, userId) {
    state.userId = userId;
  },
  SET_FROM_DATE(state, fromDate) {
    state.fromDate = fromDate;
  },
  SET_TO_DATE(state, toDate) {
    state.toDate = toDate;
  },
  SET_SYNONYMS(state, synonyms) {
    state.synonyms = synonyms;
  },
  SET_SYNONYMS_LOADING(state, loadingState) {
    state.synonymsLoading = loadingState;
  },
  SET_SYNONYMS_LOADED(state, loadedState) {
    state.synonymsLoaded = loadedState;
  },
  SET_HEALTHCHECK_LOADING(state, loadedState) {
    state.healthcheckLoading = loadedState;
  },
  SET_HEALTHCHECK(state, loadedState) {
    state.healthcheck = loadedState;
  },
  SET_ORDERS_LOADING(state, loadedState) {
    state.ordersLoading = loadedState;
  },
  SET_ORDERS(state, loadedState) {
    state.orders = loadedState;
  },
  SET_PROMOTIONS(state, promotions) {
    state.promotions = promotions;
  },
  SET_PROMOTIONS_LOADING(state, loadingState) {
    state.promotionsLoading = loadingState;
  }
};
const actions = {
  GetLogs({ state, commit }, { userId, fromDate, toDate }) {
    return new Promise((resolve, reject) => {
      if (userId) {
        commit('SET_USER_ID', userId);
      } else {
        commit('SET_USER_ID', null);
      }

      if (fromDate) {
        commit('SET_FROM_DATE', fromDate);
      } else {
        commit('SET_FROM_DATE', null);
      }

      if (toDate) {
        commit('SET_TO_DATE', toDate);
      } else {
        commit('SET_TO_DATE', null);
      }

      axiosInstance({
        url: '/api/logs',
        params: {
          userId: state.userId ?? undefined,
          fromDate: state.fromDate ?? undefined,
        }
      })
        .then((data) => {
          commit('SET_LOGS', data);
        })
        .catch(err => reject(err));
    });
  },

  async GetAccounts(_, name) {
    return new Promise(((resolve, reject) => {
      axiosInstance({
        url: '/api/admin/accounts',
        params: {
          max: 10,
          offset: 0,
          name,
        }
      })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    }));
  },

  async GetAllAccounts({ state, commit }, { name, haveUsers, active }) {
    commit('SET_ADMIN_EMPTY_TEXT', 'Загрузка');

    let allData;
    if (name) {
      commit('SET_NAME_FILTER', name);
    } else {
      commit('SET_NAME_FILTER', '');
    }

    if (haveUsers !== null) {
      commit('SET_HAVE_USERS_FILTER', haveUsers);
    } else {
      commit('SET_HAVE_USERS_FILTER', null);
    }

    if (active !== null) {
      commit('SET_ACTIVE_USERS_FILTER', active);
    } else {
      commit('SET_ACTIVE_USERS_FILTER', null);
    }

    await axiosInstance({
      url: '/api/admin/accounts',
      params: {
        max: state.max,
        offset: state.allAccounts.length,
        name: state.nameFilter ?? undefined,
        haveUsers: state.haveUsers ?? undefined,
        active: state.active ?? undefined,
      }
    })
      .then((data) => {
        if (data.length === 0) {
          commit('SET_ADMIN_EMPTY_TEXT', 'Нет данных');
        } else {
          commit('SET_ADMIN_EMPTY_TEXT', 'Загрузка');
        }

        allData = data;
        commit('SET_ALL_ACCOUNTS', data);
      })
      .catch(err => new Error(err));

    return Promise.resolve(allData);
  },

  async GetUsersByAccount(_, { accountId }) {
    let dataAll;
    await axiosInstance({
      url: '/api/admin/usersByAccount',
      params: {
        accountId: accountId ?? undefined,
      }
    })
      .then((data) => {
        dataAll = data;
        // commit(SET_ALL_ACCOUNTS, data);
      })
      .catch(err => new Error(err));

    return Promise.resolve(dataAll);
  },

  async LoginAsUser(_, { userId }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/admin/loginAsUser',
        method: 'post',
        data: {
          userId,
        }

      })
        .then(data => resolve(data))
        .catch(err => reject(new Error(err.message || err)));
    });
  },
  async DeleteCache(_, { accountId }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/admin/cache',
        method: 'delete',
        data: {
          accountId,
        }

      })
        .then(data => resolve(data))
        .catch(err => reject(new Error(err.message || err)));
    });
  },

  /**
   * Получить весь чейнджлог
   *
   * @returns {Promise<{
   * id: number,
   * text: string,
   * createdAt: string,
   * updatedAt: string,
   * }[] | Error>} Updates
   */
  GetUpdates() {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/admin/updates',
        method: 'GET'
      })
        .then(data => resolve(data))
        .catch(err => reject(err));
    });
  },

  /**
   * Отправить текст новой версии
   *
   * @param {any} _
   * @param {string} text - Текст обновления
   * @returns {Promise<{
   * id: number,
   * text: string,
   * createdAt: string,
   * updatedAt: string,
   * } | Error>}
   */
  PushUpdate(_, text) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/admin/updates',
        method: 'POST',
        data: { text }
      })
        .then(data => resolve(data))
        .catch(err => reject(err));
    });
  },

  DellAllRedisKeys() {
    return axiosInstance('/api/admin/dellAllRedisKeys');
  },
  /**
   * Upload file with synonyms list
   * @param commit
   * @param {FormData} file File
   * @returns {Promise<Synonyms>}
   */
  UploadSynonyms({ commit }, file) {
    commit('SET_SYNONYMS_LOADING', true);
    return new Promise((resolve, reject) => {
      axiosInstance({
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        url: '/api/admin/uploadSynonyms',
        method: 'POST',
        data: file
      })
        .then((response) => {
          commit('SET_SYNONYMS', response);
          resolve(response);
        })
        .catch((err) => {
          reject(new Error(err.message || err));
        })
        .finally(() => {
          commit('SET_SYNONYMS_LOADING', false);
        });
    });
  },
  /**
   * Create synonyms
   * @param _
   * @param {Object} synonyms synonyms list
   * @returns {Promise<boolean>}
   */
  CreateSynonyms({ commit }, synonyms) {
    commit('SET_SYNONYMS_LOADING', true);
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/admin/createSynonyms',
        method: 'POST',
        data: {
          synonyms
        }
      })
        .then(data => resolve(data))
        .catch(err => reject(err))
        .finally(() => {
          commit('SET_SYNONYMS_LOADING', false);
        });
    });
  },
  async getHealthCheck({ commit }) {
    commit('SET_HEALTHCHECK_LOADING', true);
    await axiosInstance({
      url: '/healthcheck',
    })
      .then((data) => {
        commit('SET_HEALTHCHECK', data);
      })
      .finally(() => {
        commit('SET_HEALTHCHECK_LOADING', false);
      });
  },
  /**
   *
   * @param commit
   * @param {'year'|'month'|'dayOfWeek'|'hour'} type
   * @param from
   * @param to
   * @param {boolean} orderSum вместо количества заказов считает сумму
   * @returns {Promise<void>}
   */
  async getOrders({ commit }, { type, from, to, orderSum = false }) {
    commit('SET_ORDERS_LOADING', true);
    await axiosInstance({
      url: `/api/admin/data/orders/${type}`,
      params: {
        from,
        to,
        orderSum
      }
    })
      .then((data) => {
        commit('SET_ORDERS', data.map(item => {
          item.count = item.count || item.totalsum;
          return item;
        }));
      })
      .finally(() => {
        commit('SET_ORDERS_LOADING', false);
      });
  },
  async getInfoRequests(_, date) {
    return axiosInstance({
      url: `api/admin/infoRequests`,
      params: {
        date,
      }
    })
  },
  async setUserSegment(_, {userId, segment = ''}) {
    await axiosInstance({
      url: '/api/admin/users/' + userId,
      method: 'patch',
      data: {
        segment: segment.toUpperCase()
      }
    });
  },
  async clickhouseExport() {
    await axiosInstance({
      url: '/api/admin/clickhouse/export',
      method: 'post',
    });
  },
  /**
   *
   * @param _
   * @param {number} page
   * @param {number} limit
   * @param {string} search
   * @param status
   * @param type
   * @param startDateFrom
   * @param startDateTo
   * @param endDateFrom
   * @param endDateTo
   * @param productId
   * @param sortBy
   * @param sortOrder
   * @returns {Promise<*>}
   */
  async getPromotions({commit}, {
    page = 1,
    limit = 20,
    search = '',
    status = [],
    type = [],
    startDateFrom = undefined,
    startDateTo = undefined,
    endDateFrom = undefined,
    endDateTo = undefined,
    productId = undefined,
    sortBy = 'createdAt',
    sortOrder = 'DESC',
  } = {}) {
    commit('SET_PROMOTIONS_LOADING', true);
    return axiosInstance({
      url: `api/admin/promotions`,
      params: {
        page,
        limit,
        search,
        status,
        type,
        startDateFrom,
        startDateTo,
        endDateFrom,
        endDateTo,
        productId,
        sortBy,
        sortOrder,
      }
    }).then(response => {
      commit('SET_PROMOTIONS', response);
    }).finally(() => {
      commit('SET_PROMOTIONS_LOADING', false);
    })
  },
  async createPromotion(_, promotionData) {
    const formData = new FormData();
    const keysToOmit = ['status', 'banner'];

    // Add all non-banner data as JSON string
    const cleanData = Object.fromEntries(
      Object.entries(promotionData).filter(([key]) => !keysToOmit.includes(key))
    );
    formData.append('data', JSON.stringify({ ...cleanData, condition: 'sum' }));

    // Add banner file if exists
    if (promotionData.banner) {
      formData.append('banner', promotionData.banner);
    }

    return axiosInstance({
      method: "POST",
      url: `api/admin/promotions`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  async updatePromotion(_, promotionData) {
    const formData = new FormData();
    const { id, ...data } = promotionData.data;
    const keysToOmit = ['clickCount', 'viewCount', 'dismissCount', 'bannerUrl', 'createdAt', 'updatedAt', 'Products', 'banner'];

    // Add all non-banner data as JSON string
    const cleanData = Object.fromEntries(
      Object.entries(data).filter(([key]) => !keysToOmit.includes(key))
    );
    formData.append('data', JSON.stringify({ ...cleanData, condition: 'sum' }));

    // Add banner file if exists
    if (promotionData.banner) {
      formData.append('banner', promotionData.banner);
    }

    return axiosInstance({
      method: 'PUT',
      url: `api/admin/promotions/${id}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  async deletePromotionAction(_, promotionId) {
    return axiosInstance({
      method: 'DELETE',
      url: `api/admin/promotions/${promotionId}`
    })
  },
};

const getters = {
  getInfo: state => state.logs.filter(item => item.level === 'info' && item.url.includes(state.subjectFilter)),
  getErrors: state => state.logs.filter(item => item.level === 'error' && item.url.includes(state.subjectFilter)),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
